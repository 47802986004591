<template>
    <div style="display:flex;height:100%;">
        <van-sidebar v-model="active">
            <van-sidebar-item title="配网"></van-sidebar-item>
            <van-sidebar-item title="平整度"></van-sidebar-item>
            <van-sidebar-item title="氯离子"></van-sidebar-item>
            <van-sidebar-item title="静力触探"></van-sidebar-item>
            <van-sidebar-item title="灌立停"></van-sidebar-item>
            <van-sidebar-item title="车辙"></van-sidebar-item>
            <van-sidebar-item title="无核密度"></van-sidebar-item>
            <van-sidebar-item title="路晨伟业"></van-sidebar-item>
            <van-sidebar-item title="无线测温"></van-sidebar-item>
            <van-sidebar-item title="软化点"></van-sidebar-item>
            <van-sidebar-item title="测力仪"></van-sidebar-item>
            <van-sidebar-item title="路强仪"></van-sidebar-item>
            <van-sidebar-item title="测缝仪"></van-sidebar-item>
            <van-sidebar-item title="针入度"></van-sidebar-item>
            <van-sidebar-item title="延伸仪"></van-sidebar-item>
        </van-sidebar>
        <div style="flex:1" v-if="active == 0">
            <van-cell-group title="配网">
                <van-cell title="一键配网" is-link url="download/esptouch.apk" value="下载"></van-cell>
            </van-cell-group>
        </div>
        <div style="flex:1" v-if="active == 1">
            <van-cell-group title="平整度">
                <van-cell title="蓝牙平整度" is-link url="download/PZDly/pzdly.apk" value="下载"></van-cell>
                <van-cell title="蓝牙平整度BLE" is-link url="download/PZD100/pzd100.apk" value="下载"></van-cell>
                <van-cell title="7型平整度" is-link url="download/PZD801/xing7.apk" value="下载"></van-cell>
                <van-cell title="8型平整度" is-link url="download/PZD801/xing8.apk" value="下载"></van-cell>
                <van-cell title="说明书" is-link url="download/PZD801/readme.pdf" value="下载"></van-cell>
                <van-cell title="视频教程" is-link url="download/PZD801/xing8.apk" value="下载"></van-cell>
            </van-cell-group>
        </div>
        <div style="flex:1" v-if="active == 2">
            <van-cell-group title="氯离子">
                <van-cell title="氯离子" is-link url="download/LLZ008/llz008.apk" value="下载"></van-cell>
                <van-cell title="新氯离子" is-link url="download/LLZ009/llz009.apk" value="下载"></van-cell>
                <van-cell title="氯离子801(PH)" is-link url="https://bjlcyq.cn/download/llz801/llz801.apk" value="下载"></van-cell>
                <van-cell title="氯离子801(无PH)" is-link url="https://bjlcyq.cn/download/llz801/llz801_1.apk" value="下载"></van-cell>
            </van-cell-group>
        </div>
        <div style="flex:1" v-if="active == 3">
            <van-cell-group title="静力触探">
                <van-cell title="双通道" is-link url="download/jlct02/jlct02.apk" value="下载"></van-cell>
                <van-cell title="静力触探2022" is-link url="download/jlct82/jlct82.apk" value="下载"></van-cell>
            </van-cell-group>
        </div>
        <div style="flex:1" v-if="active == 4">
            <van-cell-group title="灌立停">
                <van-cell title="灌立停" is-link url="download/GLTZ01/GLTZ01.apk" value="下载"></van-cell>
            </van-cell-group>
        </div>
        <div style="flex:1" v-if="active == 5">
            <van-cell-group title="车辙">
                <van-cell title="车辙（WIFI,三位小数）" is-link url="download/CZY004/czy004.apk" value="下载"></van-cell>
                <van-cell title="车辙（WIFI,两位小数）" is-link url="download/CZY001/chezhe20200731.apk" value="下载"></van-cell>
                <van-cell title="车辙（蓝牙）" is-link url="download/czyly/czyly.apk" value="下载"></van-cell>
                <van-cell title="车辙仪（005）" is-link url="download/CZY005/CZY005.apk" value="下载"></van-cell>
                <van-cell title="车辙仪（006）" is-link url="download/CZY006/CZY006.apk" value="下载"></van-cell>
                <van-cell title="车辙仪_006_Win_SerialPort" is-link url="https://bjlcyq.cn/download/czy/czy006.rar" value="下载"></van-cell>
            </van-cell-group>
        </div>
        <div style="flex:1" v-if="active == 6">
            <van-cell-group title="无核密度仪">
                <van-cell title="专业版" is-link url="download/WHMD01/WHMD01.apk" value="下载"></van-cell>
                <van-cell title="简单版" is-link url="download/WHMD01/WHMD0120210515.apk" value="下载"></van-cell>
            </van-cell-group>
        </div>
        <div style="flex:1" v-if="active == 7">
            <van-cell-group title="路晨伟业服务端">
                <van-cell title="路晨伟业服务端" is-link url="https://bjlcyq.cn/download/%E8%B7%AF%E6%99%A8%E4%BC%9F%E4%B8%9A%E8%BD%AF%E4%BB%B6.exe" value="下载"></van-cell>
                <van-cell title="fm4.8(必须联网)" is-link url="https://bjlcyq.cn/download/framework/ndp48-web.exe" value="下载"></van-cell>
                <van-cell title="BLE工具" is-link url="https://bjlcyq.cn/download/BLE/base.apk" value="下载"></van-cell>
                <van-cell title="WMSAPP" is-link url="https://bjlcyq.cn/download/sumjwms/wmsapp.apk" value="下载"></van-cell>
            </van-cell-group>
        </div>
        <div style="flex:1" v-if="active == 8">
            <van-cell-group title="无线测温">
                <van-cell title="同步工具" is-link url="https://bjlcyq.cn/download/%E6%96%AF%E5%A7%86%E6%8D%B7USB%E8%BD%AF%E4%BB%B6.exe" value="下载"></van-cell>
                <van-cell title="虚拟终端" is-link url="https://bjlcyq.cn/download/wxcw/WXCW_Virtual_Device.exe" value="下载"></van-cell>
            </van-cell-group>
        </div>
        <div style="flex:1" v-if="active == 9">
            <van-cell-group title="软化点">
                <van-cell title="视频软化点" is-link url="download/RHD100/RHD100.apk" value="下载"></van-cell>
                <van-cell title="标定软件" is-link url="download/RHD100/bd.apk" value="下载"></van-cell>
            </van-cell-group>
        </div>
        <div style="flex:1" v-if="active == 10">
            <van-cell-group title="测力仪">
                <van-cell title="测力仪" is-link url="download/CLY001/CLY001.apk" value="下载"></van-cell>
            </van-cell-group>
        </div>
        <div style="flex:1" v-if="active == 11">
            <van-cell-group title="综合路强仪">
                <van-cell title="综合路强仪" is-link url="download/lqy108/lqy108.apk" value="下载"></van-cell>
            </van-cell-group>
        </div>
        <div style="flex:1" v-if="active == 12">
            <van-cell-group title="测缝仪">
                <van-cell title="测缝仪" is-link url="https://bjlcyq.cn/download/CFY001/cfy001.apk" value="下载"></van-cell>
            </van-cell-group>
        </div>
        <div style="flex:1" v-if="active == 13">
            <van-cell-group title="针入度">
                <van-cell title="针入度800" is-link url="https://bjlcyq.cn/download/ZRD800/zrd800.apk" value="下载"></van-cell>
            </van-cell-group>
        </div>
        <div style="flex:1" v-if="active == 14">
            <van-cell-group title="延伸仪">
                <van-cell title="延伸仪010" is-link url="https://bjlcyq.cn/download/YSY010/%E6%B2%A5%E9%9D%92%E5%BB%B6%E5%BA%A6.exe" value="下载"></van-cell>
            </van-cell-group>
        </div>
        <van-overlay :show="showOverlay" @click="show = false">
            <van-image src="images/yindao.png" />
        </van-overlay>
    </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
import "vant/lib/index.css";

Vue.use(Vant);
export default {
    data() {
        return {
            active: "0",
            showOverlay: false,
        };
    },
    mounted() {
        let that = this;
        that.active = that.$route.query["a"] ? that.$route.query["a"] : "0";
        let ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
            that.showOverlay = true;
        } else {
            that.showOverlay = false;
        }
    },
};
</script>

<style></style>